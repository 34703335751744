import { DateTime } from "luxon";
import React from "react";
import { config } from "react-awesome-styled-grid";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { NewsArticle, NewsArticles } from "../../api/news-articles";
import { Flex } from "../../components/Flex";
import { H1 } from "../../components/Heading";
import { ExternalLinkIcon } from "../../components/icons/ExternalLinkIcon";
import { RestoreScrollTop } from "../../components/RestoreScrollTop";
import { SEO } from "../../components/SEO";
import { Stack } from "../../components/Stack";
import { GraphQlLoadable } from "../../graphql/types";
import { colors } from "../../theme";
import { ArticleCTA } from "../Blog/ArticleCTA";

const PageContainer = styled.div`
  margin: 170px auto;
  max-width: 1208px;
  padding: 0 64px;

  ${(props) => config(props).media["xs"]`
    padding: 0 16px;
  `}

  ${(props) => config(props).media["sm"]`
    padding: 0 16px;
  `}
`;

const ArticleList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const Article = styled.div`
  display: grid;
  gap: 24px;
  padding: 40px 0;

  ${(props) => config(props).media["md"]`
    grid-template-columns: 3fr 2fr;
  `}

  & + & {
    border-top: 1px solid ${colors.gray200};
  }
`;

const ArticleTitle = styled.a.attrs({ target: "_blank" })`
  color: ${colors.gray700};
  display: block;
  font-family: KurrentSemiBold;
  font-size: 30px;
  letter-spacing: -0.72px;
  line-height: 120%;
  margin: 4px 0;
  text-decoration: none;
  transition: opacity 150ms;

  &:hover {
    opacity: 0.8;
  }
`;

const Category = styled.p`
  color: ${colors.gray500};
  font-family: InterMedium;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1.68px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
`;

const Date = styled.p`
  color: ${colors.gray600};
  font-family: InterRegular;
  font-size: 16px;
  margin: 0;
  padding: 0;
`;

const StyledLink = styled.a.attrs({ target: "_blank" })`
  align-items: center;
  border-bottom: 1px solid transparent;
  color: ${colors.gray600};
  display: inline-flex;
  font-family: InterRegular;
  font-size: 16px;
  gap: 8px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const CTAContainer = styled.div`
  margin: 0 auto;
  max-width: 920px;
`;

interface Props {
  listNewsArticlesLoadable: GraphQlLoadable<NewsArticles>;
}

export const NewsPageView: React.FC<Props> = ({ listNewsArticlesLoadable }) => {
  if (!listNewsArticlesLoadable.data || listNewsArticlesLoadable.isLoading) {
    return null;
  }

  if (!listNewsArticlesLoadable.data?.newsArticles?.data?.length) {
    return <Navigate to="/not-found" replace={true} />;
  }

  const articleList = listNewsArticlesLoadable?.data?.newsArticles.data;

  return (
    <>
      <SEO
        description="Find links to our latest news and media coverage"
        title="News"
      />
      <RestoreScrollTop />
      <PageContainer>
        <Stack gap="40px">
          <H1>Recent media coverage</H1>
          <p>Find links to our latest news and media coverage...</p>
        </Stack>
        <Stack gap="104px">
          <ArticleList>
            {articleList.map((newsArticle: NewsArticle) => {
              const article = newsArticle.attributes;

              const articlePublishedDateDisplay = DateTime.fromISO(
                article.Published
              ).toFormat("d LLLL yyyy");

              return (
                <Article>
                  <div>
                    <Category>{article.Category}</Category>
                    <ArticleTitle href={article.URL}>
                      {article.Title}
                    </ArticleTitle>
                    <Date>{articlePublishedDateDisplay}</Date>
                  </div>
                  <Flex alignItems="flex-end">
                    <StyledLink href={article.URL}>
                      <span>
                        {article.LinkPrefix} <strong>{article.Source}</strong>
                      </span>
                      <ExternalLinkIcon />
                    </StyledLink>
                  </Flex>
                </Article>
              );
            })}
          </ArticleList>
          <CTAContainer>
            <ArticleCTA />
          </CTAContainer>
        </Stack>
      </PageContainer>
    </>
  );
};
