import { gql } from "@apollo/client";

export const QUERY_LIST_NEWS_ARTICLES = gql`
  query ListNewsArticles {
    newsArticles(sort: "Published:desc") {
      data {
        id
        attributes {
          Category
          LinkPrefix
          Published
          Source
          Title
          URL
        }
      }
    }
  }
`;
