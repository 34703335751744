import { useQuery } from "@apollo/client";
import React from "react";
import { createGraphQlLoadable } from "../../graphql/utils";
import { QUERY_LIST_NEWS_ARTICLES } from "./queries";
import { NewsPageView } from "./view";

export const NewsPageContainer: React.FC = () => {
  const query = useQuery(QUERY_LIST_NEWS_ARTICLES, {
    fetchPolicy: "network-only",
  });

  const listNewArticlesLoadable = createGraphQlLoadable({
    isLoading: query.loading,
    error: query.error,
    data: query.data,
  });
  return <NewsPageView listNewsArticlesLoadable={listNewArticlesLoadable} />;
};
