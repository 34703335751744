import { gql } from "@apollo/client";

export const QUERY_GET_DOCUMENT = gql`
  query GetArticle($slug: String!) {
    legals(
      filters: { slug: { eq: $slug } }
      sort: "createdAt"
      pagination: { limit: 1 }
    ) {
      data {
        id
        attributes {
          title
          createdAt
          body
          slug
        }
      }
    }
  }
`;

export const QUERY_LIST_DOCUMENTS = gql`
  query ListArticles {
    legals(sort: "createdAt") {
      data {
        id
        attributes {
          title
          createdAt
          slug
        }
      }
    }
  }
`;
