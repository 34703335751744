export const PATHS = {
  blogPost: "/blog/:id",
  company: "/company",
  contact: "/contact",
  demoRequest: "/request-a-demo",
  home: "/",
  legal: "/legal",
  legalArticle: "/legal/:slug",
  news: "/news",
  resources: "/resources",
};
